* {
  margin: 0;
  padding: 0;
}

input {
  height: 20px;
  margin: 4px;
  width: 80px;
}

span {
  color: red;
  margin: 4px;
  text-align: center;
}

button {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #888;
  height: 30px;
  margin: 8px;
  outline: none;
  transition: all 0.2s;
  width: 80px;
}

input {
  border-radius: 4px;
  background-color: #ddd;
  border: none;
  outline: none;
  padding: 2px 6px;
  transition: all 0.2s;
}

input:hover {
  background-color: #888;
  border-radius: 12px;
  border: none;
  color: #fff;
}

button:hover {
  background-color: #888;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
}

.root {
  height: 100vh;
  padding: 12px;
  width: 100vw;
}

.input-wrapper {
  align-items: center;
  display: flex;
  margin: 0;
  justify-content: space-between;
  width: 280px;
}
